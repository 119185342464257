<template>
  <div>
    <div class="air__utils__heading"> <h5>Recepción de Muestras</h5> </div>
    <my-breadcrumbs :routes="routes"/>
    <my-spinner :load="loading"/>
    <a-row :gutter="[24,8]" v-show="!loading">
      <a-col :sm="tableSizeCol.sm" :md="tableSizeCol.md" class="bg-white">
        <a-row>
          <!-- Filtros -->
          <a-row class="mb-1 py-3 px-1" align="middle" type="flex" justify="center" :gutter="[8,8]">
            <!-- Planta -->
            <a-col class="mx-0" :xs="12" :md="8">
              <a-form-item label="Planta">
                <antGlobalPlantFilter v-model="filterPlant"/>
              </a-form-item>
            </a-col>
            <!-- Cliente / Solicitante -->
            <a-col class="mx-0" :xs="12" :md="8">
              <!--TEXT - Cliente / Solicitante-->
              <a-form-item label="Cliente / Solicitante">
                <a-input v-model="filterClient" placeholder="Cliente / Solicitante" size="large"/>
              </a-form-item>
            </a-col>
            <!-- Tipo de Ingreso -->
            <a-col class="mx-0" :xs="12" :md="8">
              <a-form-item  label="Tipo de Ingreso">
                <a-select v-model="filterEntryType" allowClear
                  placeholder="Seleccionar Tipo de Ingreso" style="width: 100%">
                  <a-select-option  v-for="option in SampleTypesCombo" :key="option.value" :value="option.value">
                    {{option.text}}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <!-- Corriente -->
            <a-col class="mx-0" :xs="12" :md="12">
              <customDropdownSelect v-model="filterCurrent"
                label="Corriente" :pageSize="25"
                placeholder="Seleccionar Corriente"
                resource="currents"/>
            </a-col>
            <!-- Estatus -->
            <a-col class="mx-0" :xs="11" :md="10">
              <a-form-item  label="Estatus">
                <a-select v-model="filterStatusValue" allowClear
                  placeholder="Seleccionar Estatus" style="width: 100%">
                  <a-select-option  v-for="option in labAnalysisTransactionsStatus" :key="option.value" :value="option.value">
                    {{option.text}}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col class="mx-0 mt-3" :xs="2" :md="2">
              <b-button size="md" variant="default" @click="FetchTable()">
                <b-icon icon="search"/>
              </b-button>
            </a-col>
            <!-- Fechas -->
            <a-col class="mx-0" :xs="24" :md="12">
              <b-form-group id="from-group" label="Fecha inicial"  label-for="from">
                <b-form-datepicker v-model="filterFromDate"
                                    reset-button label-reset-button="Limpiar"
                                    id="from" label="Fecha inicial" placeholder="Desde"/>
              </b-form-group>
            </a-col>
            <a-col class="mx-0" :xs="24" :md="12">
              <b-form-group id="to-group" label="Fecha final"  label-for="to">
                <b-form-datepicker v-model="filterToDate"
                                    reset-button label-reset-button="Limpiar"
                                    id="to" label="Fecha final" placeholder="Hasta"/>
              </b-form-group>
            </a-col>
            <!-- Fechas -->
          </a-row>
          <!-- Filtros -->
        </a-row>
        <a-row>
          <!-- Tabla -->
          <a-table bordered
            :scroll="{x:1100 , y:1000}"
            :dataSource="tableObj.data"
            :columns="tableObj.columns"
            :loading="tableObj.loading"
            :row-key="record => record.id"
            :pagination="tableObj.pagination"
            :row-selection="{ width: 100, fixed:true, type: 'radio', selectedRowKeys: selectedRowKeys, selectedRows: selectedRow, onChange: onSelectChange }"
            @change="FetchTable">
            <template slot="serviceDetail" slot-scope="row">
              <div class="text-center">
                <small>
                  <strong>Centro de Trabajo:</strong>
                </small>
                <small>
                  <strong>{{ row.work_centers.code }}</strong> -
                  {{ row.work_centers.name }}
                </small>
              </div>
              <div class="text-center">
                <small>
                  <strong>Cliente / Solicitante:</strong>
                </small>
                <small>{{ row.receipt_samples.lab_customer }}</small>
              </div>
              <hr class="bg-primary">
              <div class="text-center">
                <small>
                  <strong>No.Servicio:</strong>
                </small>
                <small>
                  <strong>{{ row.receipt_samples.invoice_code }}</strong>
                </small>
              </div>
              <div class="text-center">
                <small>
                  <strong>Corriente:</strong>
                </small>
                <small>
                  {{ row.receipt_samples.currents.name }}
                </small>
              </div>
              <div class="text-center">
                <small>
                  <strong>Material:</strong>
                </small>
                <small>
                  {{ row.receipt_samples.sample_material }}
                </small>
              </div>
              <div class="text-center">
                <small>
                  <strong>Tipo de Contenedor:</strong>
                </small>
                <small>{{ row.receipt_samples.container_types.name }}</small>
              </div>
            </template>
            <template slot="sampleDetail" slot-scope="row">
              <div class="text-center">
                <small>
                  <strong>Tipo de Ingreso:</strong>
                </small>
                <small> {{ row.receipt_samples.sample_type }}</small>
              </div>
               <div class="text-center">
                <small>
                  <strong>Cantidad de Muestras tomada: </strong>
                </small>
                <small>{{ row.receipt_samples.quantity }} </small>
                <small> {{ row.receipt_samples.ums.name }}</small>
              </div>
              <hr class="bg-primary">
              <div class="text-center">
                <small>
                  <strong>Fecha de recepción:</strong>
                </small>
                <small v-if="row.receipt_samples && row.receipt_samples.receipt_sample_date && row.receipt_samples.receipt_sample_date.request_dated_at">
                  {{ moment(row.receipt_samples.receipt_sample_date.request_dated_at).format('DD/MM/YYYY') }}
                </small>
              </div>
            </template>
            <span slot="status" slot-scope="row" :class="receipt_sample_date_status_class(row.status)" v-text="row.status"/>
          </a-table>
          <!-- Tabla -->
        </a-row>
      </a-col>
      <a-col :sm="{ span: 24 }" :md="{ span:12}" class="bg-white" v-if="selectedRow.length > 0">
        <h3 class="text-center">Datos Generales</h3>
        <hr class="bg-primary">
        <a-row align="middle" type="flex" justify="center" :gutter="[16,8]">
          <a-col :sm="{ span: 24 }" :md="{ span:12}">
            <strong class="text-secondary">Planta: </strong>
            <span class="text-primary">{{selectedRow[0].work_centers.name}}</span>
            <br>
            <strong class="text-secondary">Prueba de Análisis: </strong>
            <span class="text-primary">{{selectedRow[0].lab_analysis.name}}</span>
            <br>
            <strong class="text-secondary">Fecha de inicio de Análisis: </strong>
            <span class="text-primary">
              {{moment(selectedRow[0].work_started_at).format('DD/MM/YYYY')}}
            </span>
            <br>
            <strong class="text-secondary">Fecha de término de Análisis: </strong>
            <span class="text-primary" v-if="selectedRow[0].transaction_finished_at">
               {{moment(selectedRow[0].transaction_finished_at).format('DD/MM/YYYY')}}
            </span>
            <br>
            <strong class="text-secondary">Responsable de Laboratorio: </strong>
            <span class="text-primary">{{selectedRow[0].lab_responsible}}</span>
            <br>
            <strong class="text-secondary">Análista de Laboratorio: </strong>
            <span class="text-primary">{{selectedRow[0].lab_analyst}}</span>
            <br>
          </a-col>
          <a-col :sm="{ span: 24 }" :md="{ span:12}">
            <strong class="text-secondary">Costo: </strong>
            <span class="text-primary">$ {{parseFloat(selectedRow[0].lab_analysis.cost).toFixed(2)}}</span>
            <br>
            <strong class="text-secondary">Criterio: </strong>
            <span class="text-primary">{{selectedRow[0].criteria}}</span>
            <br>
            <strong class="text-secondary">Acreditación: </strong>
            <span class="text-primary">{{selectedRow[0].lab_analysis.accreditation}}</span>
            <br>
            <strong class="text-secondary">No.Identificación: </strong>
            <span class="text-primary">{{selectedRow[0].identify_code}}</span>
            <br>
            <strong class="text-secondary">No.Formulación: </strong>
            <span class="text-primary">{{selectedRow[0].formulation_code}}</span>
            <br>
            <strong class="text-secondary">Cantidad Autorizada: </strong>
            <!-- <span class="text-primary">{{selectedRow[0].receipt_samples.quantity}} - {{selectedRow[0].receipt_samples.ums.name}}</span> -->
            <span class="text-primary">{{selectedRow[0].authorized_quantity}} - {{selectedRow[0].receipt_samples.ums.name}}</span>
            <br>
            <strong class="text-secondary">Notas: </strong>
            <p class="text-secondary">{{selectedRow[0].lab_analysis.notes}}</p>
            <br>
          </a-col>
        </a-row>
        <!--TABLE de labAnalysisElements-->
        <div v-if="!ownElements">
          <searchingBar :components="['filter']"
                @Fectch="FetchLabAnalysisElementsTable"
                @filterValue="labAnalysisElementsTableObj.search = $event">
            <a-row slot="customFilters" align="middle">
                <customDropdownSelect v-model="labAnalysisElementsWorkCenterFilter"
                                      label="Planta" resource="workCenters"/>
            </a-row>
          </searchingBar>
          <!-- Cart Modal -->
          <a-row type="flex" justify="end" v-show="labAnalysisElementsCart.length">
            <!-- Btn -->
            <a-col :span="2" class="mb-4">
                <b-button pill variant="primary" @click="ShowCart">
                <div class="d-none d-md-block" v-b-tooltip.hover title="Ver">
                  <b-icon icon="cart"/> {{labAnalysisElementsCart.length}}
                </div>
                <div class="d-md-none" v-b-tooltip.hover title="Ver">
                  <b-icon icon="cart"/>
                </div>
                </b-button>
              </a-col>
            <!-- Btn -->
            <!-- Modal -->
            <b-modal title="Elementos de Análisis" ref="modalCart" size="xl" @hide="CloseCart">
              <a-list item-layout="horizontal" bordered :data-source="labAnalysisElementsCart">
                <a-list-item slot="renderItem" slot-scope="item">
                  <a-list-item-meta title="Elemento de Análisis" :description="item.name">
                    <a-button slot="avatar" @click="RemoveFromCart(item)">
                      <a-icon type="delete" theme="twoTone" two-tone-color="#ff0000"/>
                    </a-button>
                  </a-list-item-meta>
                </a-list-item>
              </a-list>
              <template v-slot:modal-footer>
                <simple-modal-footer @ClickEvent="CloseCart"/>
              </template>
            </b-modal>
            <!-- Modal -->
          </a-row>
          <!-- Cart Modal -->
          <!--TABLA-->
          <a-row>
            <a-table bordered :scroll="{x:1300}" :columns="labAnalysisElementsTableObj.columns"
                :data-source="labAnalysisElementsTableObj.data"
                :pagination="labAnalysisElementsTableObj.pagination"
                :loading="labAnalysisElementsTableObj.loading"
                @change="FetchLabAnalysisElementsTable"
                :row-key="record => record.id">
              <template slot="current_relations" slot-scope="row">{{row.map(current => current.name).join(',')}}</template>
              <template slot="action" slot-scope="row">
                <b-button v-if="!labAnalysisElementsCart.some(cartItem => cartItem.id === row.id)"
                  size="sm" pill variant="primary" @click="AddToCart(row)">
                  <b-icon icon="plus" v-b-tooltip.hover title="Agregar"/>
                </b-button>
              </template>
            </a-table>
          </a-row>
          <!--TABLA-->
          <a-row type="flex" justify="end" class="mb-2" v-show="labAnalysisElementsCart.length">
            <a-col :span="3">
              <my-spinner :load="spinner"/>
            </a-col>
            <a-col :span="3">
              <b-button pill variant="primary" @click="AssignAnalysisItems">
              <div class="d-none d-md-block" v-b-tooltip.hover title="Guardar">
                <b-icon icon="archive"/> Guardar
              </div>
              <div class="d-md-none" v-b-tooltip.hover title="Guardar">
                <b-icon icon="archive"/>
              </div>
              </b-button>
            </a-col>
          </a-row>
        </div>
        <!--TABLE labAnalysisElements from labAnalysisTransactions-->
        <div v-else>
          <!--TABLA-->
          <a-row>
            <a-table bordered :scroll="{x:1300}" :columns="AnalysisElementsFromTransactionsTableObj.columns"
                :data-source="AnalysisElementsFromTransactionsTableObj.data"
                :pagination="AnalysisElementsFromTransactionsTableObj.pagination"
                :loading="AnalysisElementsFromTransactionsTableObj.loading"
                @change="FetchLabAnalysisElementsTable"
                :row-key="record => record.id">
              <p slot="expandedRowRender" slot-scope="row" style="margin: 0">{{ row.comments }}</p>
              <template slot="current_relations" slot-scope="row">{{row.map(current => current.name).join(',')}}</template>
              <span slot="status" slot-scope="status" :class="labAnalysisSamples_status_class(status)" v-text="status"/>
              <template slot="instruments" slot-scope="row">{{row.lab_analysis_elements.instruments}}</template>
              <template slot="action" slot-scope="row">
                <b-button v-if="showActionBtn" size="sm" pill variant="primary" @click="OpenResultModal(row)">
                  <b-icon icon="plus" v-b-tooltip.hover title="Detalles"/>
                </b-button>
                <b-button v-if="showActionBtn" pill variant="danger" @click="DeleteResult(row)">
                 <div class="d-none d-md-block" v-b-tooltip.hover title="Eliminar">
                   <b-icon icon="trash"/>
                 </div>
                 <div class="d-md-none" v-b-tooltip.hover title="Eliminar">
                   <b-icon icon="trash"/>
                 </div>
                </b-button>
              </template>
            </a-table>
          </a-row>
          <!--TABLA-->
          <!--Modal-->
          <b-modal title="Resultados de Prueba" ref="modalResult" size="xl" @hide="CloseResultModal">
            <a-form-model ref="formResult" layout="horizontal"
              :model="analysisInformationResourceObj.form" :rules="analysisInformationFormRules">
              <!--NUMBER INPUT - Resultado-->
                <a-row type="flex" justify="center">
                  <a-col :span="4">
                    <a-form-model-item label="Resultado" ref="result"  prop="result" >
                       <a-input-number v-model="analysisInformationResourceObj.form.result" :min="0" :step="0.1" size="large"/>
                    </a-form-model-item>
                  </a-col>
                  <a-col :span="10">
                    <a-form-model-item label="Estatus" ref="status" prop="status" >
                      <a-select v-model="analysisInformationResourceObj.form.status" allowClear
                                placeholder="Estatus"
                                show-search>
                        <a-select-option  v-for="option in labAnalysisSamplesStatus" :key="option.value" :value="option.value" >
                          {{option.text}}
                        </a-select-option>
                      </a-select>
                    </a-form-model-item>
                  </a-col>
                </a-row>
                <!--SELECT -Estatus -->
                <!--TEXT-AREA - Comentarios-->
                <a-row type="flex" justify="center">
                  <a-col :sm="{ span: 14 }">
                    <a-form-model-item label="Comentarios" ref="comments"  prop="comments" >
                      <a-textarea v-model="analysisInformationResourceObj.form.comments" :rows="5" allowClear/>
                    </a-form-model-item>
                  </a-col>
                </a-row>
                <!--TEXT-AREA -Comentarios -->
                <!-- Botones -->
                <a-row type="flex" justify="end" v-show="!spinner">
                  <a-col>
                    <my-spinner :load="spinner"/>
                  </a-col>
                  <a-col>
                    <b-button pill variant="primary" @click="UpdateAnalysisInformation">
                    <div class="d-none d-md-block" v-b-tooltip.hover title="Guardar">
                      <b-icon icon="archive"/> Guardar
                    </div>
                    <div class="d-md-none" v-b-tooltip.hover title="Guardar">
                      <b-icon icon="archive"/>
                    </div>
                    </b-button>
                  </a-col>
                </a-row>
                <!-- Botones -->
              </a-form-model>
            <template v-slot:modal-footer>
              <simple-modal-footer @ClickEvent="CloseResultModal"/>
            </template>
          </b-modal>
          <!--Modal-->
          <!--Modal General-->
          <b-modal title="Concluir Análisis" ref="modalGeneral" size="xl" @hide="CloseGeneralModal">
            <!-- Formulario General -->
            <a-form-model ref="formGeneral" layout="horizontal" class="mb-4"
                :model="generalResourceObj.form" :rules="generalFormRules">
              <a-row type="flex" justify="center">
                <a-col :span="12">
                  <!--TEXT - No.Identificación-->
                  <a-form-model-item label="No.Identificación" ref="identify_code"  prop="identify_code" >
                    <a-input v-model="generalResourceObj.form.identify_code" placeholder="No.Identificación" size="large"/>
                  </a-form-model-item>
                  <!--TEXT -No.Identificación -->
                </a-col>
                <a-col :span="12">
                  <!--TEXT - No.Formulación-->
                  <a-form-model-item label="No.Formulación" ref="formulation_code"  prop="formulation_code" >
                    <a-input v-model="generalResourceObj.form.formulation_code" placeholder="No.Formulación" size="large"/>
                  </a-form-model-item>
                  <!--TEXT -No.Formulación -->
                </a-col>
                <a-col :span="4">
                  <!--NUMBER INPUT - Cantidad Autorizada-->
                  <a-form-model-item label="Cantidad Autorizada" ref="authorized_quantity"  prop="authorized_quantity" >
                    <a-input-number v-model="generalResourceObj.form.authorized_quantity" :min="0" :step="0.1" size="large"/>
                  </a-form-model-item>
                  <!--NUMBER INPUT -Cantidad Autorizada -->
                </a-col>
                <a-col :span="10">
                  <!--CUSTOM SELECT-->
                  <customDropdownSelect v-model="generalResourceObj.form.u_m_s_id" refAndPop="u_m_s_id"
                    label="UM" resource="ums"/>
                  <!--CUSTOM SELECT-->
                </a-col>
                <a-col :span="10">
                  <!--TEXT - Criterio-->
                  <a-form-model-item label="Criterio" ref="criteria"  prop="criteria" >
                    <a-input v-model="generalResourceObj.form.criteria" placeholder="Criterio" size="large"/>
                  </a-form-model-item>
                  <!--TEXT -Criterio -->
                </a-col>
              </a-row>
              <a-row type="flex" justify="center">
                <a-col :span="8">
                  <!--DATEPICKER - Fecha de Termino de Análisis-->
                  <a-form-model-item  label="Fecha de Termino de Análisis" ref="transaction_finished_at" prop="transaction_finished_at" >
                    <a-date-picker  v-model="generalResourceObj.form.transaction_finished_at" style="width:100%"
                                    placeholder="Fecha de Termino de Análisis"
                                    format="DD/MM/YYYY" size="large" valueFormat="YYYY-MM-DD"/>
                  </a-form-model-item>
                  <!--DATEPICKER -Fecha de Termino de Análisis -->
                </a-col>
                <a-col :span="8">
                  <!--DATEPICKER - Fecha de Reporte -->
                  <a-form-model-item  label="Fecha de Reporte" ref="report_dated_at" prop="report_dated_at" >
                    <a-date-picker  v-model="generalResourceObj.form.report_dated_at" style="width:100%"
                                    placeholder="Fecha de Reporte"
                                    format="DD/MM/YYYY" size="large" valueFormat="YYYY-MM-DD"/>
                  </a-form-model-item>
                  <!--DATEPICKER -Fecha de Reporte -->
                </a-col>
                <a-col :span="8">
                  <!--DATEPICKER - Fecha de Termino de Análisis-->
                  <a-form-model-item  label="Estatus" ref="status" prop="status" >
                    <a-select v-model="generalResourceObj.form.status" allowClear
                        placeholder="Seleccionar Estatus" style="width: 100%">
                      <a-select-option  v-for="option in labAnalysisTransactionsStatus"
                          :key="option.value" :value="option.value">
                        {{option.text}}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                  <!--DATEPICKER -Fecha de Termino de Análisis -->
                </a-col>
                <a-col :span="12">
                  <!--TEXT - Responsable de Laboratorio-->
                  <a-form-model-item label="Responsable de Laboratorio" ref="lab_responsible"  prop="lab_responsible" >
                     <a-input v-model="generalResourceObj.form.lab_responsible" placeholder="Responsable de Laboratorio" size="large"/>
                  </a-form-model-item>
                  <!--TEXT -Responsable de Laboratorio -->
                </a-col>
                <a-col :span="12">
                  <!--TEXT - Análista de Laboratorio-->
                  <a-form-model-item label="Análista de Laboratorio" ref="lab_analyst"  prop="lab_analyst" >
                     <a-input v-model="generalResourceObj.form.lab_analyst" placeholder="Análista de Laboratorio" size="large"/>
                  </a-form-model-item>
                  <!--TEXT -Análista de Laboratorio -->
                </a-col>
                <!--TEXT-AREA - Observaciones Generales-->
                <a-col :span="24">
                  <a-form-model-item label="Observaciones Generales" ref="general_comments"  prop="general_comments" >
                    <a-textarea v-model="generalResourceObj.form.general_comments" :rows="4" allowClear/>
                  </a-form-model-item>
                </a-col>
              </a-row>
              <!--TEXT-AREA -Observaciones Generales-->
              <!-- Botones -->
              <a-row type="flex" justify="end" v-show="!spinner">
                <a-col>
                  <my-spinner :load="spinner"/>
                </a-col>
                <a-col>
                  <b-button pill variant="primary" @click="UpdateGeneral">
                  <div class="d-none d-md-block" v-b-tooltip.hover title="Guardar">
                    <b-icon icon="archive"/> Guardar
                  </div>
                  <div class="d-md-none" v-b-tooltip.hover title="Guardar">
                    <b-icon icon="archive"/>
                  </div>
                  </b-button>
                </a-col>
              </a-row>
              <!-- Botones -->
            </a-form-model>
            <!-- Formulario General -->
            <template v-slot:modal-footer>
              <simple-modal-footer @ClickEvent="CloseGeneralModal"/>
            </template>
          </b-modal>
          <!-- Modal General -->
          <!-- Boton Siguiente -->
          <a-row type="flex" justify="end" class="mb-4">
            <a-col>
              <b-button pill variant="default" @click="OpenGeneralModal">
                <div class="d-none d--block" v-b-tooltip.hover title="Siguiente">
                  <b-icon icon="arrow-right"/>
                </div>
                <div class="d--none" v-b-tooltip.hover title="Siguiente">
                  <b-icon icon="arrow-right"/>
                </div>
              </b-button>
            </a-col>
          </a-row>
          <!-- Boton Siguiente -->
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import moment from 'moment'
import { Modal } from 'ant-design-vue'
import { apiV2Mixin } from '@/mixins/ApiV2Mixin'
import {
  SampleTypesCombo, StatusValueCombo,
  labAnalysisSamplesStatus, labAnalysisTransactionsStatus,
} from '@/constant/CombosApiV2'
const analysisInformationDefaultForm = {
  lab_analysis_element_id: null,
  result: 0,
  status: 'Aceptada',
  comments: '',
}
const generalDefaultForm = {
  identify_code: '',
  formulation_code: '',
  authorized_quantity: 0,
  u_m_s_id: null,
  criteria: '',
  transaction_finished_at: '',
  analysis_dated_at: '',
  report_dated_at: '',
  lab_responsible: '',
  lab_analyst: '',
  status: '',
  general_comments: '',
}
export default {
  name: 'sampleAnalisis',
  mixins: [apiV2Mixin],
  data() {
    return {
      // combos
      SampleTypesCombo,
      StatusValueCombo,
      labAnalysisSamplesStatus,
      labAnalysisTransactionsStatus,
      // consts
      analysisInformationDefault: JSON.stringify(analysisInformationDefaultForm),
      generalDefault: JSON.stringify(generalDefaultForm),
      // breadcrumbs
      routes: [
        {
          name: 'home',
          breadcrumbName: 'Inicio',
          bIcon: 'house-fill',
        },
        {
          name: 'laboratories',
          breadcrumbName: 'Laboratorio',
        },
        {
          breadcrumbName: 'Análisis de Muestras',
          aIcon: '',
        },
      ],
      // Filters
      filterPlant: undefined,
      filterClient: '',
      filterEntryType: undefined,
      filterCurrent: undefined,
      filterStatusValue: undefined,
      filterFromDate: '',
      filterToDate: '',
      // Table of labAnalysisTransactions
      tableObj: {
        data: [],
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 0,
          lastPage: 0,
        },
        loading: false,
        columns: [
          { title: 'Código', width: 100, dataIndex: 'code', key: 'code', fixed: 'left', class: 'text-center small' },
          {
            title: 'Detalle del Servicio',
            key: 'serviceDetail',
            class: 'text-center small',
            scopedSlots: { customRender: 'serviceDetail' },
          },
          {
            title: 'Datos de la muestra',
            key: 'sampleDetail',
            class: 'text-center small',
            scopedSlots: { customRender: 'sampleDetail' },
          },
          {
            title: 'Estatus',
            key: 'status',
            fixed: 'right',
            class: 'text-center small',
            width: 100,
            scopedSlots: { customRender: 'status' },
          },
        ],
      },
      // Table of labAnalysisElements
      labAnalysisElementsWorkCenterFilter: undefined,
      labAnalysisElementsTableObj: {
        data: [],
        search: '',
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 0,
          lastPage: 0,
        },
        loading: false,
        columns: [
          { title: 'Elementos de Análisis', dataIndex: 'name', key: 'name', class: 'text-center small' },
          { title: 'Corrientes', class: 'text-center small', dataIndex: 'current_relations', key: 'current_relations', scopedSlots: { customRender: 'current_relations' } },
          { title: 'Niv Inferior', dataIndex: 'down_limit', key: 'down_limit', class: 'text-center small' },
          { title: 'Lim. Superior', dataIndex: 'up_limit', key: 'up_limit', class: 'text-center small' },
          { title: 'Referencia', dataIndex: 'reference', key: 'reference', class: 'text-center small' },
          { title: 'UM', dataIndex: 'ums.name', key: 'ums', class: 'text-center small' },
          { title: 'Instrumentos', dataIndex: 'instruments', key: 'instruments', class: 'text-center small' },
          {
            title: 'Acción',
            key: 'x',
            fixed: 'right',
            width: 100,
            scopedSlots: { customRender: 'action' },
          },
        ],
      },
      selectedRow: [],
      selectedRowKeys: [],
      labAnalysisElementsCart: [],
      spinner: false,
      loading: false,
      ownElements: false,
      // Table of labAnalysisElements from labAnalysisTransactions
      showActionBtn: true,
      AnalysisElementsFromTransactionsTableObj: {
        data: [],
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 0,
          lastPage: 0,
        },
        loading: false,
        columns: [
          { title: 'Elementos de Análisis', dataIndex: 'lab_analysis_elements.name', key: 'name', class: 'text-center small' },
          { title: 'Corrientes', dataIndex: 'lab_analysis_elements.current_relations', key: 'current_relations', class: 'text-center small', scopedSlots: { customRender: 'current_relations' } },
          { title: 'Niv Inferior', dataIndex: 'lab_analysis_elements.down_limit', key: 'down_limit', class: 'text-center small' },
          { title: 'Lim. Superior', dataIndex: 'lab_analysis_elements.up_limit', key: 'up_limit', class: 'text-center small' },
          { title: 'Referencia', dataIndex: 'lab_analysis_elements.reference', key: 'reference', class: 'text-center small' },
          { title: 'UM', dataIndex: 'lab_analysis_elements.ums.name', key: '', class: 'text-center small' },
          { title: 'Instrumentos', dataIndex: '', key: '', class: 'text-center small', scopedSlots: { customRender: 'instruments' } },
          { title: 'Resultado', dataIndex: 'result', key: '', class: 'text-center small' },
          { title: 'Estatus', dataIndex: 'status', key: '', class: 'text-center small', scopedSlots: { customRender: 'status' } },
          { title: 'Acción', dataIndex: '', class: 'text-center small', key: 'x', scopedSlots: { customRender: 'action' } },
        ],
      },
      analysisInformationResourceObj: {
        resourceType: 'labAnalysisSamples/',
        form: {},
      },
      analysisInformationFormRules: {
        lab_analysis_element_id: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
        result: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
        status: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
      },
      generalResourceObj: {
        resourceType: 'labAnalysisTransactions/',
        form: {},
      },
      generalFormRules: {
        identify_code: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
        formulation_code: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
        authorized_quantity: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
        u_m_s_id: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
        criteria: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
        transaction_finished_at: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
        report_dated_at: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
        lab_responsible: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
        lab_analyst: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
        status: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
        general_comments: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
      },
      // Actualizar análisis de muestra
    }
  },
  computed: {
    tableSizeCol () {
      if (this.selectedRow.length > 0) {
        return { sm: { span: 24 }, md: { span: 12 } }
      } else return { sm: { span: 24 }, md: { span: 24 } }
    },
  },
  methods: {
    // helpers
    receipt_sample_date_status_class(status) {
      switch (status) {
        case 'Pendiente':
          return 'font-weight-bold text-warning'
        case 'Cancelado':
          return 'font-weight-bold text-danger'
        case 'Concluido':
          return 'font-weight-bold text-primary'
        case 'No Aplica':
          return 'font-weight-bold text-secondary'
        default:
          return 'font-weight-bold text-secondary'
      }
    },
    labAnalysisSamples_status_class(status) {
      switch (status) {
        case 'Pendiente':
          return 'font-weight-bold text-warning'
        case 'Rechazada':
          return 'font-weight-bold text-danger'
        case 'Aceptada':
        case 'Aceptada con Desviación':
          return 'font-weight-bold text-primary'
        case 'No Aplica':
          return 'font-weight-bold text-secondary'
        default:
          return 'font-weight-bold text-secondary'
      }
    },
    // helpers
    // Table of labAnalysisTransactions
    async FetchTable(pagE) {
      const params = {
        orderField: 'code',
        orderType: 'asc',
      }
      if (this.filterPlant) params.workCenterFilter = this.filterPlant // planta
      if (this.filterCurrent) params.currentId = this.filterCurrent // corriente
      if (this.filterStatusValue) params.status = this.filterStatusValue // estatus
      if (this.filterFromDate) params.initialDate = moment(this.filterFromDate).format('YYYY-DD-MM') // fecha inicial
      if (this.filterToDate) params.finalDate = moment(this.filterToDate).format('YYYY-DD-MM') // fecha final
      if (this.filterEntryType) params.sampleType = this.filterEntryType // tipo de ingreso
      if (this.filterClient || this.filterEntryType) params.search = this.filterClient

      await this.GetTableResourcev2(pagE, 'labAnalysisTransactions', this.tableObj, params)
    },
    async onSelectChange(_selectedRowKeys, selectedRows) {
      this.selectedRow = selectedRows
      this.selectedRowKeys = _selectedRowKeys
      if (selectedRows.length > 0 && selectedRows[0].status !== 'Pendiente') {
        this.ownElements = true
        await this.GetElementsAssigned2Analysis()
        this.generalResourceObj.resourceType = `labAnalysisTransactions/${selectedRows[0].id}`
      } else this.ownElements = false
      if (selectedRows.length > 0 && selectedRows[0].status === 'En Proceso') {
        this.showActionBtn = true
      } else this.showActionBtn = false
    },
    // Table of labAnalysisTransactions
    // Table of labAnalysisElements
    async FetchLabAnalysisElementsTable(pagE) {
      const params = {
        orderField: 'code',
        orderType: 'asc',
      }
      if (this.labAnalysisElementsTableObj.search) params.search = this.labAnalysisElementsTableObj.search
      if (this.labAnalysisElementsWorkCenterFilter) params.workCenterId = this.labAnalysisElementsWorkCenterFilter

      await this.GetTableResourcev2(pagE, 'labAnalysisElements', this.labAnalysisElementsTableObj, params)
    },
    AddToCart(labAnalysisElement) {
      if (!this.labAnalysisElementsCart.some(cartItem => cartItem.id === labAnalysisElement.id)) {
        this.labAnalysisElementsCart.push(labAnalysisElement)
      } else {
        console.log('show error')
      }
    },
    RemoveFromCart(labAnalysisElement) {
      const i = this.labAnalysisElementsCart.map(item => item.id).indexOf(labAnalysisElement.id)
      this.labAnalysisElementsCart.splice(i, 1)
    },
    ShowCart() {
      this.spinner = false
      this.$refs.modalCart.show()
    },
    CloseCart() {
      this.spinner = false
      this.$refs.modalCart.hide()
    },
    async AssignAnalysisItems() {
      const me = this
      Modal.confirm({
        title: '¿Esta seguro de asignar los análisis a la muestra?',
        okText: 'Sí',
        okType: 'primary',
        cancelText: 'No',
        zIndex: 3000,
        async onOk () {
          me.spinner = true
          const resObj = {
            resourceType: `labAnalysisTransactions/samples/${me.selectedRow[0].id}`,
            form: { lab_analysis_samples: [] },
          }
          me.labAnalysisElementsCart.forEach(item => {
            resObj.form.lab_analysis_samples.push({
              lab_analysis_element_id: item.id,
              result: 0,
              status: 'Pendiente',
              comments: '',
            })
          })
          await me.PostResourcev2(resObj, async () => {
            me.spinner = false
            await me.UpdateStatus()
            me.labAnalysisElementsCart = []
          }, () => {
            me.spinner = false
          })
        },
      })
    },
    // Table of labAnalysisElements
    // Table of labAnalysisElements from labAnalysisTransactions
    async GetElementsAssigned2Analysis(pagE) {
      this.loading = true
      if (this.selectedRow.length > 0 && this.selectedRow[0].id) {
        const params = {
          labAnalysisTransactionId: this.selectedRow[0].id,
          orderField: 'id',
          orderType: 'asc',
        }
        if (this.AnalysisElementsFromTransactionsTableObj.search) params.search = this.AnalysisElementsFromTransactionsTableObj.search
        await this.GetTableResourcev2(pagE, 'labAnalysisSamples', this.AnalysisElementsFromTransactionsTableObj, params)
      }
      this.loading = false
    },
    OpenResultModal(analysisInformation) {
      this.analysisInformationResourceObj.form = JSON.parse(this.analysisInformationDefault)
      this.analysisInformationResourceObj.form.result = Number(analysisInformation.result)
      this.analysisInformationResourceObj.form.status = analysisInformation.status
      this.analysisInformationResourceObj.form.comments = analysisInformation.comments
      this.analysisInformationResourceObj.form.lab_analysis_element_id = parseInt(analysisInformation.lab_analysis_element_id)
      this.analysisInformationResourceObj.resourceType = `labAnalysisSamples/${analysisInformation.id}`
      this.spinner = false
      this.$refs.modalResult.show()
    },
    CloseResultModal() {
      this.spinner = false
      this.$refs.modalResult.hide()
      this.analysisInformationResourceObj.form = JSON.parse(this.analysisInformationDefault)
      this.analysisInformationResourceObj.resourceType = 'labAnalysisSamples/'
    },
    DeleteResult(analysisInformation) {
      const me = this
      Modal.confirm({
        title: '¿Esta seguro de eliminar el análisis a la muestra?',
        okText: 'Sí',
        okType: 'danger',
        cancelText: 'No',
        zIndex: 3000,
        async onOk () {
          me.loading = true
          const url = `labAnalysisSamples/${analysisInformation.id}`
          await me.AxiosDelete(url, {}, async () => {
            me.loading = false
            const i = me.AnalysisElementsFromTransactionsTableObj.data.map(item => item.id).indexOf(analysisInformation.id)
            me.AnalysisElementsFromTransactionsTableObj.data.splice(i, 1)
          }, async () => {
            me.loading = false
          })
        },
      })
    },
    UpdateAnalysisInformation() {
      this.$refs.formResult.validate(async(valid) => {
        if (valid) {
          this.spinner = true
          await this.PutResourcev2(this.analysisInformationResourceObj, async () => {
            this.spinner = false
            this.CloseResultModal()
            await this.GetElementsAssigned2Analysis()
          }, () => {
            this.spinner = false
          })
        } else return false
      })
    },
    // Table of labAnalysisElements from labAnalysisTransactions
    // Actualizar análisis de muestra
    async UpdateStatus() {
      const form = {
        resourceType: `labAnalysisTransactions/status/${this.selectedRow[0].id}`,
        form: { status: 'En Proceso' },
      }
      this.loading = true
      await this.PutResourcev2(form, async () => {
        this.loading = false
        this.selectedRow[0].status = 'En Proceso'
        this.selectedRowKeys = []
        this.selectedRow = []
      }, async () => {
        this.loading = false
      })
    },
    OpenGeneralModal() {
      this.generalResourceObj.form = JSON.parse(this.generalDefault)
      this.generalResourceObj.resourceType = `labAnalysisTransactions/${this.selectedRow[0].id}`
      // set up the form
      this.generalResourceObj.form.identify_code = this.selectedRow[0].identify_code
      this.generalResourceObj.form.formulation_code = this.selectedRow[0].formulation_code
      this.generalResourceObj.form.authorized_quantity = this.selectedRow[0].authorized_quantity
      this.generalResourceObj.form.u_m_s_id = this.selectedRow[0].u_m_s_id ? parseInt(this.selectedRow[0].u_m_s_id) : undefined
      this.generalResourceObj.form.criteria = this.selectedRow[0].criteria
      this.generalResourceObj.form.transaction_finished_at = this.selectedRow[0].transaction_finished_at
      this.generalResourceObj.form.report_dated_at = this.selectedRow[0].report_dated_at
      this.generalResourceObj.form.lab_responsible = this.selectedRow[0].lab_responsible
      this.generalResourceObj.form.lab_analyst = this.selectedRow[0].lab_analyst
      this.generalResourceObj.form.status = this.selectedRow[0].status
      this.generalResourceObj.form.general_comments = this.selectedRow[0].general_comments
      this.spinner = false
      this.$refs.modalGeneral.show()
    },
    CloseGeneralModal() {
      this.spinner = false
      this.$refs.modalGeneral.hide()
      this.generalResourceObj.form = JSON.parse(this.generalDefault)
      this.generalResourceObj.resourceType = 'labAnalysisTransactions/'
    },
    UpdateGeneral() {
      this.$refs.formGeneral.validate(async(valid) => {
        if (valid) {
          this.spinner = true
          this.generalResourceObj.form.analysis_dated_at = moment().format('YYYY-MM-DD')
          await this.PutResourcev2(this.generalResourceObj, async () => {
            this.spinner = false
            this.selectedRowKeys = []
            this.selectedRow = []
            await this.FetchTable()
          }, async () => {
            this.spinner = false
          })
        } else return false
      })
    },
    // Actualizar análisis de muestra
  },
  async mounted() {
    await this.FetchTable()
    await this.FetchLabAnalysisElementsTable()
  },
}
</script>

<style>

</style>
